import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import { ContentCard } from './content-card';
import type { ContentItem } from '@/lib/types';

interface ContentLayoutProps {
  audiobooks: ContentItem[];
  ebooks: ContentItem[];
  articles: ContentItem[];
  podcasts: ContentItem[];
  activeShelf?: string | null;
  onAddToShelf?: (contentId: string, contentType: string) => void;
}

export function ContentLayout({ 
  audiobooks, 
  ebooks, 
  articles, 
  podcasts, 
  activeShelf,
  onAddToShelf
}: ContentLayoutProps) {
  // Get featured content first
  const featuredBooks = [...audiobooks, ...ebooks]
    .filter(item => item.featured)
    .sort((a, b) => {
      // Sort by views first, then by date
      if (a.views !== b.views) return b.views - a.views;
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

  // Get remaining content
  const remainingBooks = [...audiobooks, ...ebooks]
    .filter(item => !item.featured)
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

  // Combine featured and remaining content
  const combinedBooks = [...featuredBooks, ...remainingBooks];

  // Create content sections that will repeat
  const createContentSection = (startIndex: number) => {
    const booksPerRow = {
      xl: 7, // Extra large screens
      lg: 6, // Large screens
      md: 4, // Medium screens
      sm: 3, // Small screens
      base: 2 // Mobile
    };

    const articlesPerRow = {
      xl: 5, // Extra large screens
      lg: 4, // Large screens
      md: 3, // Medium screens
      sm: 2, // Small screens
      base: 1 // Mobile
    };

    // Get books for two rows
    const sectionBooks = combinedBooks.slice(startIndex, startIndex + booksPerRow.xl * 2);
    const bookRows = chunk(sectionBooks, booksPerRow.xl);

    // Get featured articles and podcasts first
    const featuredArticles = articles
      .filter(item => item.featured)
      .sort((a, b) => b.views - a.views);
    const featuredPodcasts = podcasts
      .filter(item => item.featured)
      .sort((a, b) => b.views - a.views);

    // Get remaining articles and podcasts
    const remainingArticles = articles
      .filter(item => !item.featured)
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    const remainingPodcasts = podcasts
      .filter(item => !item.featured)
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    // Combine featured and remaining content
    const sectionArticles = [...featuredArticles, ...remainingArticles]
      .slice(startIndex / 2, startIndex / 2 + articlesPerRow.xl);
    const sectionPodcasts = [...featuredPodcasts, ...remainingPodcasts]
      .slice(startIndex / 2, startIndex / 2 + articlesPerRow.xl);

    return (
      <div className="space-y-12">
        {/* First row of books */}
        {bookRows[0]?.length > 0 && (
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-semibold">
                {startIndex === 0 ? 'Featured Books' : 'More Books to Explore'}
              </h2>
              <Link 
                to="/explore/books" 
                className="flex items-center gap-1 text-sm text-primary hover:underline"
              >
                View all books
                <ChevronRight className="w-4 h-4" />
              </Link>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 gap-4">
              {bookRows[0].map(item => (
                <ContentCard 
                  key={item.id} 
                  item={item} 
                  activeShelf={activeShelf}
                  onAddToShelf={onAddToShelf}
                />
              ))}
            </div>
          </div>
        )}

        {/* Second row of books */}
        {bookRows[1]?.length > 0 && (
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-semibold">Popular Books</h2>
              <Link 
                to="/explore/books" 
                className="flex items-center gap-1 text-sm text-primary hover:underline"
              >
                View all books
                <ChevronRight className="w-4 h-4" />
              </Link>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 gap-4">
              {bookRows[1].map(item => (
                <ContentCard 
                  key={item.id} 
                  item={item} 
                  activeShelf={activeShelf}
                  onAddToShelf={onAddToShelf}
                />
              ))}
            </div>
          </div>
        )}

        {/* Articles row */}
        {sectionArticles.length > 0 && (
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-semibold">Latest Articles</h2>
              <Link 
                to="/explore/articles" 
                className="flex items-center gap-1 text-sm text-primary hover:underline"
              >
                View all articles
                <ChevronRight className="w-4 h-4" />
              </Link>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
              {sectionArticles.map(item => (
                <ContentCard 
                  key={item.id} 
                  item={item} 
                  activeShelf={activeShelf}
                  onAddToShelf={onAddToShelf}
                />
              ))}
            </div>
          </div>
        )}

        {/* Podcasts row */}
        {sectionPodcasts.length > 0 && (
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-semibold">Featured Podcasts</h2>
              <Link 
                to="/explore/podcasts" 
                className="flex items-center gap-1 text-sm text-primary hover:underline"
              >
                View all podcasts
                <ChevronRight className="w-4 h-4" />
              </Link>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
              {sectionPodcasts.map(item => (
                <ContentCard 
                  key={item.id} 
                  item={item} 
                  activeShelf={activeShelf}
                  onAddToShelf={onAddToShelf}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  // Helper function to chunk arrays
  const chunk = <T,>(arr: T[], size: number): T[][] => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
      arr.slice(i * size, i * size + size)
    );
  };

  // Create multiple sections
  const sections = [];
  for (let i = 0; i < combinedBooks.length; i += 14) { // 7 books per row * 2 rows
    sections.push(createContentSection(i));
  }

  return <div className="space-y-16">{sections}</div>;
}